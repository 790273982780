import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import {buildImageObj, cn, toPlainText} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import {colors, font, fontfamily} from '../styles/variables'

import Heading from './heading'
import Button from './button'

const Wrapper = styled.article`
  display: block;
  padding: 4rem 0;
`

const ImageWrapper = styled.div`
  display: block;
  margin: 0 2.4rem 4rem 2.4rem;
`

const Image = styled.img`
  display: block;
  border-radius: 5px;
`

const Text = styled.div`
  margin: 0 2.4rem 4rem 2.4rem;
`

const List = styled.ul`
  display: block;
  max-width: 680px;
  margin: 0 auto;
`

const Item = styled.li`
  margin: 0 0 4rem 0;
  font-size: 1.6rem;
`

const StyledLink = styled(props => <Link {...props} />)`

`

const Faq = props => {
  const {title, titleEn, _rawBody, mainImage} = props
  return (
    <Wrapper>
      <Heading title={title} titleEn={titleEn} />
      {mainImage && mainImage.asset &&
        <ImageWrapper>
          <Image
            src={imageUrlFor(buildImageObj(mainImage))
              .width(2400)
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </ImageWrapper>
      }

      {_rawBody && (
        <Text>
          <PortableText blocks={_rawBody} />
        </Text>
      ) }
      <Text>
        <List>
          <Item>
            <StyledLink to='/faq/general/'>一般的なご質問へ(入学・編入を含む)</StyledLink>
          </Item>
          <Item>
            <StyledLink to='/faq/summer-winter-schools/'>サマー・ウィンターキャンプのご質問へ</StyledLink>
          </Item>
          <Item>
            <StyledLink to='/faq/eiken/'>英検(実用英語技能検定)のご質問へ</StyledLink>
          </Item>
        </List>
      </Text>

    </Wrapper>
  )
}
export default Faq
